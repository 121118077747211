<template>
  <div class="w-full">
    <template v-if="isBnn">
      <v-row v-bind="{ justify: 'center' }" class="mb-3">
        <div class="img-box">
          <img class="icon img-end" :src="image" />
        </div>
      </v-row>

      <v-row
        v-bind="{ justify: 'center' }"
        class="align-item item-center mb-4"
      >
        <h3 class="text-3xl text-center">
          รอชำระเงิน
        </h3>
      </v-row>

      <v-row
        v-if="isDeposit"
        v-bind="{ justify: 'center', 'no-gutters': true }"
      >
        <span class="text-xl font-semibold"
          >เลขที่ใบรับของ (บิลมัดจำ) : {{ depositNumber }}</span
        ></v-row
      >

      <v-row
        v-if="refId && showRefId"
        v-bind="{ justify: 'center', 'no-gutters': true }"
      >
        <span class="text-xl font-semibold">
          เลขที่ใบจอง: {{ refId }}
        </span>
      </v-row>
    </template>

    <div class="text-center my-4">
      <div>
        กรุณานำ QR Code แสดงให้เจ้าหน้าที่ เพื่อดำเนินการชำระเงิน
      </div>
    </div>

    <div class="text-center">
      <div :class="['qr-code-box active']">
        <qrcode-vue :value="deepLink" size="140"></qrcode-vue>
      </div>

      <v-chip color="error" label class="mt-4">
        <template v-if="countDown > 0">
          ภายใน {{ timer }} วินาที
        </template>
        <template v-else>
          หมดเวลาทำรายการ
        </template>
      </v-chip>

      <div
        v-if="isBetweenBeforeExpired || isExpired"
        class="text-red text-sm mt-4"
      >
        หากไม่ได้ทำรายการชำระเงินภายในเวลาที่กำหนด
        <br />
        สามารถกดปุ่ม "ขยายเวลา" เพื่อขยายเวลาชำระเงินได้เพียง 1 ครั้ง
      </div>

      <div v-else class="text-red text-sm mt-4">
        เพื่อป้องกันสิทธิ์การจองของคุณ
        <br />
        กรุณาอย่าเปิดเผย QR Code ให้ผู้อื่นทราบ
      </div>

      <v-row
        v-if="isBetweenBeforeExpired || isExpired"
        v-bind="{ justify: 'center' }"
        class="mt-4"
      >
        <v-col cols="6" md="3">
          <ExtendTimePayAtStore
            :is-disabled="!isCanExtendPayAtStore"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import isBnn from '@/config/isBnn'
import moment from 'moment'
import colorBnn from '@/config/color'

export default {
  name: 'FlashMessagePayAtStore',

  props: {
    image: String,
    color: String,
    refId: String,
    showRefId: Boolean,
  },

  components: {
    QrcodeVue: () => import('qrcode.vue'),
    ExtendTimePayAtStore: () =>
      import('@/components/bnn/ExtendTimePayAtStore'),
  },

  data() {
    return {
      colorBnn,
      moment,
      deepLink: `${window.location.origin}/confirm-payment/${this.$route.params.uid}`,
      showQRCode: false,
      countDown: 0,
      countDownInterval: null,
      isShowExtendTime: false,
    }
  },

  computed: {
    ...mapState({
      order: state => state.order.data,
      payAtStoreExpireAtTimestamp: state =>
        state.order?.data?.pay_at_store_expire_at_timestamp_bkk ??
        null,
      isExpired: state => state.order.isExpired,
    }),
    timer() {
      let format = 'mm : ss'

      if (this.countDown > 3600) {
        format = 'HH : mm : ss'
      }

      return moment.utc(this.countDown * 1000).format(format)
    },
    isBetweenBeforeExpired() {
      // Note: 300 seconds is 5 minutes
      return this.countDown <= 300 && this.countDown > 0
    },
    isCanExtendPayAtStore() {
      return (
        this.order?.is_can_extend_pay_at_store &&
        this.isBetweenBeforeExpired
      )
    },
    isDeposit() {
      return this.order?.is_deposit
    },
    depositNumber() {
      return this.order?.deposit_number ?? 'กำลังดำเนินการ'
    },
  },

  async mounted() {},

  methods: {
    getDesc2(value) {
      const regex = /ติดต่อเจ้าหน้าที่ \(fb\)/gm
      if (value.indexOf('ติดต่อเจ้าหน้าที่ (fb)') > -1) {
        const html = isBnn
          ? `<a class="d-inline-flex align-flex-end ml-2" href="https://www.facebook.com/Bananaitshop/" target="_blank">ติดต่อเจ้าหน้าที่ <img width="28px" height="24px" src="${require('@/assets/facebook-logo.png')}" class="ml-2"/></a>`
          : `<a class="d-inline-flex align-flex-end ml-2" href="https://www.facebook.com/Studio7Thailand/" target="_blank">ติดต่อเจ้าหน้าที่ <img width="24px" height="24px" src="${require('@/assets/facebook-logo.png')}" class="ml-2"/></a>`
        return value.replace(regex, html)
      }
      return value
    },

    countDownTimer() {
      // Clear existing interval if any
      if (this.countDownInterval) {
        clearInterval(this.countDownInterval)
      }

      this.countDownInterval = setInterval(() => {
        // Calculate the difference in seconds between the current time and the expiration timestamp
        this.countDown = moment
          .unix(this.payAtStoreExpireAtTimestamp)
          .diff(moment(), 'seconds')

        if (this.countDown <= 0) {
          // Stop the interval when countDown reaches 0
          clearInterval(this.countDownInterval)

          // Dispatch action to check if the pay at store option has expired
          this.$store.dispatch('order/isPayAtStoreExpired')
        }
      }, 1000)
    },
  },

  watch: {
    payAtStoreExpireAtTimestamp: {
      handler() {
        this.countDown = moment
          .unix(this.payAtStoreExpireAtTimestamp)
          .diff(moment(), 'seconds')

        this.countDownTimer()
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    // Clear the interval when the component is about to be destroyed
    if (this.countDownInterval) clearInterval(this.countDownInterval)
  },
}
</script>

<style lang="stylus">
.img-box
  width 64px
  height 64px
  margin 0 auto
  background-color: $color-warning
  border-radius 50%
  padding 12px

.img-end
  width 100%
  height 100%
  max-width 100px
  object-fit contain
  object-position center

.qr-code-box
  background-color: #dedede
  width 140px
  height 140px
  margin 0 auto
  display flex
  justify-content center
  align-items center
  &.active {
	background-color: #fff
  }
  div {
	width: fit-content
	height: fit-content
  }
  .btn-show-qr-code {
    background-color: #000
	color: #fff
	width: fit-content
	height: auto
	padding: 8px
	aspect-ratio: 1 / 1
	border-radius: 50%
	display: flex;
    align-items: center;
	font-size: 12px;
	cursor: pointer;
  }

.actions-container {
  position: fixed
  bottom: 0
  left: 0

  width: 100%
  padding: 8px 16px
  z-index: 10

  background-color: #fff
  box-shadow: 1px 1px 2px 1px $color-gray-200;
}
</style>
